import { TFunction } from "i18next"
import { z } from "zod"

export const CurrentMatchSchema = z.object({
  game_id: z.string(),
  game_name: z.string(),
  started_at: z.string(),
  thumbnail_url: z.string().url(),
  title: z.string(),
  user_name: z.string(),
  viewer_count: z.number(),
})

const AccountSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().email(),
  isTalent: z.boolean(),
  avatar: z.string().url(),
  hash_stream: z.string().url(),
  profile_description: z.string().optional(),
  whisper_message: z.string().optional(),
  current_match: CurrentMatchSchema.nullable(),
  stripe_pending_requirements: z.array(z.string()).optional(),
})

export function UserPixInfoSchema(t?: TFunction<"en", undefined>) {
  return z
    .object({
      username: z
        .string({
          required_error: t?.("CheckOut.GameAccount.errors.Username.required"),
          invalid_type_error: t?.("CheckOut.GameAccount.errors.Username.type"),
        })
        .max(60, { message: t?.("CheckOut.GameAccount.errors.Username.max") })

        .min(1, { message: t?.("CheckOut.GameAccount.errors.Username.min") }),
      payment_with_pix: z.boolean(),
      client_doc: z.string().max(11).min(11).optional(),
      client_name: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (data.payment_with_pix) {
        if (!data.client_doc) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              // t?.("CheckOut.GameAccount.errors.ClientDoc.required") ||s
              "Documento obrigatório",
            path: ["client_doc"],
          })
        }
        if (!data.client_name) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              // t?.("") ||
              "Nome obrigatório",
            path: ["client_name"],
          })
        }
      }
    })
}

export type TAccountPerformance = {
  amount: number
  matches_played: number
  currency: string
}

export type TUserPixInfo = z.infer<ReturnType<typeof UserPixInfoSchema>>
export type TCurrentMatch = z.infer<typeof CurrentMatchSchema>
export type TAccount = z.infer<typeof AccountSchema>
