import clsx from "clsx"
import { ReactNode} from "react"

type StepProgressProps = {
  steps: Array<{ title: string }>
  currentStep: number

}

export function StepProgress({ steps, currentStep }: StepProgressProps) {
  return (
    <div className="flex w-full justify-center">
      {steps.map((step, index) => (
        <Step
          key={index}
          title={step.title}
          stepIndex={index + 1}
          currentStep={currentStep}
        />
      ))}
    </div>
  )
}

type StepProps = {
  title: ReactNode
  stepIndex: number
  currentStep: number
}

function Step({ title, stepIndex, currentStep }: StepProps) {
  return (
    <div className="group flex h-full w-1/2 flex-col items-center justify-start gap-2">
      <div className="relative flex w-full items-center justify-center">
        <div
          className={clsx(
            "absolute m-0 h-1 w-full transition-all duration-1000 group-first:rounded-l-md group-last:rounded-r-md",
            currentStep >= stepIndex
              ? stepIndex === currentStep
                ? "bg-gradient-to-r from-primary from-40% to-slate-600 to-60%"
                : "bg-primary"
              : "bg-slate-600",
          )}
        />
        <div
          className={clsx(
            "z-10 flex size-3 items-center justify-center rounded-full transition-all duration-1000",
            currentStep >= stepIndex ? "bg-primary" : "bg-slate-600",
          )}
        />
      </div>
      <span className="text-sm font-bold">{title}</span>
    </div>
  )
}
