export function getLandingPagePath() {
  return `/`
}
export function getPaymentMethods() {
  return "/portal/payment_methods"
}

export function getStreamerOnboardingStartPath() {
  return `/portal/streamer/onboarding/start`
}

export function getStreamerOnboardingDuoPath() {
  return `/portal/streamer/onboarding/duo`
}

export function getStreamerOnboardingStripePath() {
  return `/portal/streamer/onboarding/stripe`
}

export function getStreamerOnboardingPaymentMethodsPath() {
  return `/portal/streamer/onboarding/payment_methods`
}

export function getDuoMatchesPath(duoId = ":duo_id") {
  return `/portal/duo/${duoId}`
}

export function getCreateDuoPath() {
  return `/portal/duo/create`
}

export function getEditDuoPath(duoId = ":duo_id") {
  return `/portal/duo/${duoId}/edit/`
}

export function getStreamerProfilePath(streamerName = ":talent_nickname") {
  return `/${streamerName}`
}

export function getAllStreamersPath() {
  return `/talents/all`
}

export function getOverviewPath() {
  return `/portal/overview`
}

export function getEditProfilePath() {
  return `/portal/profile`
}

export function getFaqPath() {
  return `/faq`
}

export function getTermsPath() {
  return `/terms`
}

export function getLoginPath() {
  return `/login`
}

export function getObsPath(streamerName = ":talent_nickname") {
  return `/obs/${streamerName}`
}

export function getAuthCallbackPath(identifier = ":identifier") {
  return `/auth/callback/${identifier}`
}

export function getPaymentPath(query?: { talent: string; duoId: string }) {
  if (!query) return `/payment`

  return `/payment?talent=${query.talent}&product_id=${query.duoId}`
}

export function getCompletionPath(orderId?: string) {
  if (!orderId) return `/completion`

  return `/completion?order=${orderId}`
}
