import React, { useReducer } from "react"
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form"
import { Input } from "../ui/input"
import { UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"

type TextInputProps = {
  form: UseFormReturn<any>
  name: string
  label: string
  placeholder: string
  before?: React.ReactNode
}


export default function MoneyInput(props: TextInputProps) {
  const { t } = useTranslation()
  const moneyFormatter = Intl.NumberFormat("pt-BR", {
    currency: props.form.watch("currency") || "BRL",
    currencyDisplay: "symbol",
    currencySign: "standard",
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  
  const initialValue = props.form.getValues()[props.name]
    ? moneyFormatter.format(props.form.getValues()[props.name] / 100)
    : ""

  const [value, setValue] = useReducer((_: any, next: string) => {
    const digits = next.replace(/\D/g, "")
    const parsedValue = moneyFormatter.format(Number(digits) / 100)
    return parsedValue
  }, initialValue)

  function handleChange(realChangeFn: Function, formattedValue: string) {
    const digits = formattedValue.replace(/\D/g, "")
    const realValue = Number(digits)
    realChangeFn(realValue)
  }

  return (
    <FormField
      control={props.form.control}
      name={props.name}
      render={({ field }) => {
        const number_value = field.value || 0
        field.value = value
        const _change = field.onChange
        return (
          <FormItem>
            <FormLabel>{props.label}</FormLabel>
            <FormControl>
              <div className="flex items-center space-x-4">
                {props.before}
                <Input
                  placeholder={props.placeholder}
                  type="text"
                  {...field}
                  onChange={(ev) => {
                    setValue(ev.target.value)
                    handleChange(_change, ev.target.value)
                  }}
                  value={value}
                />
              </div>
            </FormControl>
            <FormDescription className="flex justify-between text-sm text-gray-400">
              <span>
                {t("createMatch.serviceTax", {
                  value: (number_value * 0.002).toFixed(2),
                })}
              </span>
              <span>
                {t("createMatch.youWillReceive", {
                  value: (number_value * 0.008).toFixed(2),
                })}
              </span>
            </FormDescription>
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}
