import { useStripePromise } from "../../react-query/useStripePromise"
import { useLocation } from "react-router-dom"
import { GetOrder, PatchOrderNotification } from "../../services/request/Order"
import { useQuery } from "@tanstack/react-query"
import { TOrder } from "../../types"

type TCompletionResponse = {
  order?: TOrder
  isLoading: boolean
}

export function CompletionVerify(): TCompletionResponse {
  const { data: stripePromise } = useStripePromise()

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const order_id = params.get("order")
  const clientSecret = params.get("payment_intent_client_secret")

  const { data: currentOrder, isLoading: isLoadingCurrentOrder } = useQuery({
    queryKey: ["GetOrder", order_id],
    queryFn: () => GetOrder({ id: order_id!, isClient: true }),
    enabled: !!order_id,
  })
  const isPaid = currentOrder?.status === "PAID"

  const { data: payment, isLoading: isLoadingPayment } = useQuery({
    queryKey: ["GetPaymentIntent"],
    queryFn: () => stripePromise?.retrievePaymentIntent(clientSecret!),
    enabled: !!stripePromise && !!clientSecret && !isPaid,
  })

  const { data: newOrder, isLoading: isLoadingOrder } = useQuery({
    queryKey: ["PatchOrderNotification"],
    queryFn: async () =>
      PatchOrderNotification({
        payment_intent: payment?.paymentIntent?.id!,
        order_id: order_id!,
      }),
    enabled: !!payment?.paymentIntent?.id && !!order_id && !isPaid,
  })

  const order = isPaid ? currentOrder : newOrder
  const isLoading = isLoadingOrder || isLoadingPayment || isLoadingCurrentOrder

  return { order, isLoading }
}
