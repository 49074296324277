import { useQuery } from "@tanstack/react-query"
import { GetAccountByNickname } from "../../services/request/Account"
import { useAuthContext } from "../../context/auth"
export const USER_QUERY_KEY = "USER_KEY"
export function useUser() {
  const { user } = useAuthContext()

  return useQuery({
    queryKey: [USER_QUERY_KEY],
    queryFn: async () => {
      if (user) {
        const User = await GetAccountByNickname(user.name)
        return User
      }
      return undefined
    },
  })
}
