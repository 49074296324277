import {
  TAccount,
  TProduct,
  TUserPixInfo,
} from "../../../types"
import { FirstStep } from "./firstStep"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { PostOrder } from "../../../services/request/Order"
import { useAuthContext } from "../../../context/auth"
import { Spinner } from "@/components/Spinner"
import { StepProgress } from "@/components/StepProgress"
import { Elements } from "@stripe/react-stripe-js"
import { CheckoutForm } from "@/pages/payment/CheckoutForm"
import { stripeCheckoutStyles } from "@/styles/stripeCheckout"
import i18n from "@/i18n"
import QRCode from "../QRcode"
import { useStripePromise } from "@/react-query/useStripePromise"
import { useState } from "react"

type PaymentStepsProps = {
  product?: TProduct
  talent?: TAccount
}

export default function PaymentSteps({ product, talent }: PaymentStepsProps) {
  const { t } = useTranslation()
  const { user } = useAuthContext()
  const [userInfo, setUserInfo] = useState<TUserPixInfo>()
  const { data: stripePromise } = useStripePromise()

  const { data: order, isLoading } = useQuery({
    queryKey: ["PostOrder"],
    queryFn: () =>
      PostOrder({
        product_id: product!.id!,
        account_id: user.userId,
        account_talent_id: product?.account_id!,
        game_access: userInfo?.username!,
        live_message: userInfo?.client_name!,
        payment_with_pix: userInfo?.payment_with_pix,
        client_doc: userInfo?.client_doc,
        client_name: userInfo?.client_name,
      }),
    enabled: !!product && !!userInfo,
  })
  const isPix = order && userInfo?.payment_with_pix

  return (
    <div className="flex grow flex-col rounded-sm border border-slate-800">
      <div className="mx-auto w-full max-w-2xl px-10 py-20">
        <StepProgress
          currentStep={!order ? 1 : 2}
          steps={[
            { title: t("CheckOut.GameAccount.Title") },
            { title: t("CheckOut.Payment.Title") },
          ]}
        />
        <div className="mt-8 flex w-full items-center justify-center">
          {isLoading ? (
            <div className="flex items-center">
              <Spinner className="size-10 text-primary" />
            </div>
          ) : (
            <FirstStep disabled={!!order} setData={setUserInfo} />
          )}
          {isPix ? (
            <QRCode order={order} />
          ) : (
            order &&
            stripePromise && (
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret: order.client_secret,
                    appearance: stripeCheckoutStyles.appearance,
                    locale: i18n.language === "en" ? "en" : "pt-BR",
                    loader: "always",
                  }}
                >
                  <CheckoutForm orderId={order.order_id} />
                </Elements>
            )
          )}
        </div>
      </div>
    </div>
  )
}
