import { FormControl, FormField, FormItem, FormMessage } from "../ui/form"
import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select"
import { UseFormReturn } from "react-hook-form"

type SelectFormProps = {
  form: UseFormReturn<any>
  name: string
}

export function CurrencyInput(props: SelectFormProps) {
  return (
    <FormField
      control={props.form.control}
      name={props.name}
      render={({ field }) => {
        return (
          <FormItem>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger className="uppercase w-20">
                  {field.value || "BRL"}
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value="usd">USD</SelectItem>
                <SelectItem value="eur">EUR</SelectItem>
                <SelectItem value="brl">BRL</SelectItem>
              </SelectContent>
              <FormMessage />
            </Select>
          </FormItem>
        )
      }}
    />
  )
}
