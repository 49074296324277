import { useUser } from "@/react-query/query/useUser"
import { UserPicture } from "@/components/UserPicture"
import { CopyObsLink } from "./CopyObsLink"
import { DefaultWhisper } from "./WhisperMessage"
import { useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { StreamerPublicProfile } from "@/views/Overview/StreamerOverview/StreamerPublicProfile"
import { Card, CardContent, CardFooter, CardTitle } from "@/components/ui/card"

export default function Profile() {
  const { data: user } = useUser()
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.profile.title")}</title>
        <meta name="description" content={t("Helmet.profile.title")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <div className="flex flex-wrap items-stretch gap-6 md:container">
        <div className="mx-auto flex flex-col gap-4 md:max-w-[300px]">
          <Card>
            <CardContent className="pt-6">
              <UserPicture alt={user?.name} src={user?.avatar} size="big" />
            </CardContent>
            <CardFooter>
              <CardTitle>
                <h1 className="text-center text-sm font-bold text-white md:text-left">
                  {user?.name}
                </h1>
                <span className="text-description">{user?.email}</span>
                <p className="mt-2 text-sm text-gray-400">
                  {user?.profile_description}
                </p>
              </CardTitle>
            </CardFooter>
          </Card>
        </div>

        <div className="flex grow flex-col gap-6">
          <DefaultWhisper />

          <StreamerPublicProfile />

          <CopyObsLink />
        </div>
      </div>
    </>
  )
}
