import { StreamerOnboardingSteps } from "../StreamerOnboardingSteps";
import { OnboardingStepDescription } from "../OnboardingStepDescription";
import { useQuery } from "@tanstack/react-query";
import { GetLinkToConnectStripe } from "@/services/request/Account";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { CreditCard, HandCoins, Wallet } from "lucide-react";
import { Spinner } from "@/components/Spinner";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { PixSetup } from "./PixSetup";

export default function StreamerOnboardingPaymentMethods() {
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);

  const { refetch: refetchStripe } = useQuery({
    queryKey: ["StripeConnect"],
    queryFn: async () => {
      const { link_to_connect } = await GetLinkToConnectStripe();
      window.location.href = link_to_connect;
    },
    enabled: false,
  });

  return (
    <div className="md:container space-y-20">
      <StreamerOnboardingSteps currentStep={2} />
      
      <div className="text-center max-w-2xl mx-auto mb-10">
        <h2 className="text-3xl font-bold tracking-tight">Métodos de Pagamento</h2>
        <p className="text-muted-foreground mt-2">
          Configure seus métodos de pagamento para receber valores das suas partidas vendidas.
        </p>
      </div>

      <Tabs defaultValue="stripe" className="w-full max-w-[500px] mx-auto">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="stripe">
            Stripe
          </TabsTrigger>
          <TabsTrigger value="pix">
            Pix
          </TabsTrigger>
        </TabsList>
        
        <TabsContent value="stripe">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <CreditCard className="h-5 w-5 text-emerald-500" />
                Stripe
              </CardTitle>
              <CardDescription>
                Conecte sua conta Stripe para receber pagamentos globalmente.
              </CardDescription>
            </CardHeader>
            <CardContent className="pt-2">
              <OnboardingStepDescription
                icon={<CreditCard className="text-emerald-500 h-10 w-10" />}
                cta={
                  <Button
                    disabled={isLoadingStripe}
                    onClick={() => {
                      setIsLoadingStripe(true);
                      refetchStripe();
                    }}
                  >
                    {isLoadingStripe ? <Spinner className="mr-2" /> : <Wallet className="mr-2" />}
                    Conectar Stripe
                  </Button>
                }
              >
                <p>Para receber pagamentos, você precisa conectar sua conta Stripe.</p>
              </OnboardingStepDescription>
            </CardContent>
          </Card>
        </TabsContent>
        
        <TabsContent value="pix">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <HandCoins className="h-5 w-5 text-emerald-500" />
                Pix
              </CardTitle>
              <CardDescription>
                Use Pix para pagamentos rápidos e seguros no Brasil.
              </CardDescription>
            </CardHeader>
            <CardContent className="pt-2">
              <PixSetup />
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
} 
