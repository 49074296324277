import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Slider } from "@/components/ui/slider"
import { ChevronRight, Users } from "lucide-react"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { TProduct, TUpdateProduct, updateProductSchema } from "@/types"
import { useTranslation } from "react-i18next"

import { GetProduct, PutProduct } from "@/services/request/Product"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import MoneyInput from "@/components/money-input"
import { toast } from "sonner"
import { DuoPage } from "../../../views/Duo/DuoPage"
import { getDuoMatchesPath, getOverviewPath } from "@/lib/getRoutePath"
import { DeleteDuoButton } from "@/views/Duo/DeleteDuoButton"
import { ToggleDuo } from "@/views/Duo/ToggleDuo"
import { CurrencyInput } from "@/components/currency-input"

export default function UpdateDuoWrapper() {
  const { duo_id } = useParams()

  const {
    data: product,
    refetch,
    isSuccess,
  } = useQuery({
    queryKey: ["GetProduct", duo_id],
    queryFn: () => GetProduct(duo_id!),
    enabled: !!duo_id,
  })

  if (!isSuccess) return null

  return <UpdateDuo product={product} refetch={refetch} />
}

export function UpdateDuo({
  product,
  refetch,
}: {
  product: TProduct
  refetch: () => void
}) {
  const { t } = useTranslation()
  const { duo_id } = useParams()

  const form = useForm<TUpdateProduct>({
    resolver: zodResolver(updateProductSchema(t)),
    defaultValues: product,
  })

  return (
    <DuoPage
      title={t("Duo.edit")}
      product={{
        ...form.getValues(),
        enable_to_sell: product.enable_to_sell,
      }}
      sideSlot={
        <div className="flex flex-col gap-6">
          <ToggleDuo product={product} />

          <div className="flex flex-col gap-2">
            <Button asChild variant="secondary">
              <Link to={getDuoMatchesPath(duo_id)}>
                {t("Duo.viewMatchesSold")}
              </Link>
            </Button>
            <DeleteDuoButton duoId={duo_id!} />
          </div>
        </div>
      }
    >
      <UpdateDuoForm form={form} duo_id={duo_id!} refetch={refetch} />
    </DuoPage>
  )
}

function UpdateDuoForm({
  duo_id,
  form,
  refetch,
}: {
  duo_id: string
  form: ReturnType<typeof useForm<TUpdateProduct>>
  refetch: () => void
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  async function onSubmit(values: TUpdateProduct) {
    try {
      await PutProduct({
        id: duo_id!,
        content: {
          ...values,
        },
      })
      refetch()
      toast.success(t("createMatch.edited"))
      navigate(getOverviewPath())
    } catch (error) {
      toast.error(t("createMatch.errorToCreate"))
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("createMatch.name.label")}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t("createMatch.name.placeholder")}
                  {...field}
                />
              </FormControl>
              <FormDescription>
                {field.value?.length || 0}/60 {t("createMatch.characters")}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("createMatch.description.label")}</FormLabel>
              <FormControl>
                <Textarea
                  placeholder={t("createMatch.description.placeholder")}
                  className="min-h-[100px]"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                {field.value?.length || 0}/124 {t("createMatch.characters")}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="platform"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("createMatch.platform.label")}</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue
                      placeholder={t("createMatch.platform.placeholder")}
                    />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="PC">PC</SelectItem>
                  <SelectItem value="PS4">PS4</SelectItem>
                  <SelectItem value="PS5">PS5</SelectItem>
                  <SelectItem value="Xbox One">Xbox One</SelectItem>
                  <SelectItem value="Xbox Series S/X">
                    Xbox Series S/X
                  </SelectItem>
                  <SelectItem value="Nintendo">Nintendo</SelectItem>
                  <SelectItem value="Android">Android</SelectItem>
                  <SelectItem value="iPhone/iPad">iPhone/iPad</SelectItem>
                  <SelectItem value="other">
                    {t("createMatch.otherDevice")}
                  </SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <FormField
            control={form.control}
            name="device"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("createMatch.device.label")}</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue
                        placeholder={t("createMatch.device.placeholder")}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="desktop">Desktop</SelectItem>
                    <SelectItem value="laptop">Laptop</SelectItem>
                    <SelectItem value="console">Console</SelectItem>
                    <SelectItem value="mobile">Mobile</SelectItem>
                    <SelectItem value="PC">PC</SelectItem>
                    <SelectItem value="PS4">PS4</SelectItem>
                    <SelectItem value="PS5">PS5</SelectItem>
                    <SelectItem value="Xbox One">Xbox One</SelectItem>
                    <SelectItem value="Xbox Series S/X">
                      Xbox Series S/X
                    </SelectItem>
                    <SelectItem value="Nintendo">Nintendo</SelectItem>
                    <SelectItem value="Android">Android</SelectItem>
                    <SelectItem value="iPhone/iPad">iPhone/iPad</SelectItem>
                    <SelectItem value="other">
                      {t("createMatch.otherDevice")}
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="region"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("createMatch.region.label")}</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue
                        placeholder={t("createMatch.region.placeholder")}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="EU">{t("Region.Europe")}</SelectItem>
                    <SelectItem value="NA">
                      {t("Region.NorthAmerica")}
                    </SelectItem>
                    <SelectItem value="SU">
                      {t("Region.SouthAmerica")}
                    </SelectItem>
                    <SelectItem value="AS">{t("Region.Asia")}</SelectItem>
                    <SelectItem value="OC">{t("Region.Oceania")}</SelectItem>
                    <SelectItem value="AF">{t("Region.Africa")}</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="amount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("createMatch.quantity")}</FormLabel>
                <FormControl>
                  <div className="flex items-center space-x-4">
                    <Users className="text-gray-400" />
                    <Slider
                      min={1}
                      max={20}
                      step={1}
                      value={[field.value]}
                      onValueChange={(value) => field.onChange(value[0])}
                      className="white flex-1"
                    />
                    <span className="min-w-[2ch] text-right text-lg font-semibold text-primary">
                      {field.value}
                    </span>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <MoneyInput
            key={form.watch("currency")}
            placeholder="$0.00"
            label={t("createMatch.value")}
            name="value"
            form={form}
            before={<CurrencyInput form={form} name="currency" />}
          />
        </div>
        <Button variant="pretty" type="submit" grow>
          {t("createMatch.confirm")} <ChevronRight className="ml-2" />
        </Button>
      </form>
    </Form>
  )
}
