import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"

import { useForm } from "react-hook-form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Slider } from "@/components/ui/slider"
import { Check, ChevronRight, ChevronsUpDown, Users } from "lucide-react"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { TCategory, TCreateProduct } from "@/types"
import { useTranslation } from "react-i18next"
import { PostProduct } from "@/services/request/Product"
import MoneyInput from "@/components/money-input"
import { toast } from "sonner"
import { useNavigate } from "react-router-dom"
import { getOverviewPath } from "@/lib/getRoutePath"
import { queryClient } from "@/lib/reactQuery"
import { Platforms } from "@/constants"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import { cn } from "@/lib/utils"
import { CurrencyInput } from "@/components/currency-input"

export function CreateDuoForm({
  form,
  categories,
  pageAfterCreation = getOverviewPath(),
}: {
  form: ReturnType<typeof useForm<TCreateProduct>>
  categories: TCategory[] | undefined
  pageAfterCreation?: string
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  async function handleSubmit(values: TCreateProduct) {
    try {
      await PostProduct({
        ...values,
      })
      toast(t("createMatch.created"))
      queryClient.invalidateQueries({ queryKey: ["getProducts"] })
      navigate(pageAfterCreation)
    } catch (error) {
      toast(t("createMatch.errorToCreate"))
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("createMatch.name.label")}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t("createMatch.name.placeholder")}
                  {...field}
                />
              </FormControl>
              <FormDescription>
                {field.value?.length || 0}/60 {t("createMatch.characters")}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("createMatch.description.label")}</FormLabel>
              <FormControl>
                <Textarea
                  placeholder={t("createMatch.description.placeholder")}
                  className="min-h-[100px]"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                {field.value?.length || 0}/124 {t("createMatch.characters")}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="product_description_id"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>{t("createMatch.category.label")}</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn(
                        "justify-between bg-secondary focus:ring-ring",
                        !field.value && "text-muted-foreground",
                      )}
                    >
                      {field.value
                        ? categories?.find(
                            (category) => category.id === field.value,
                          )?.description
                        : t("createMatch.category.placeholder")}
                      <ChevronsUpDown className="opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent align="start" className="w-full p-0">
                  <Command>
                    <CommandInput
                      placeholder="Search your game"
                      className="h-9"
                    />
                    <CommandList>
                      <CommandEmpty>No framework found.</CommandEmpty>
                      <CommandGroup>
                        {categories?.map((category) => (
                          <CommandItem
                            value={category.description}
                            key={category.id}
                            onSelect={() => {
                              form.setValue(
                                "product_description_id",
                                category.id,
                              )
                            }}
                          >
                            {category.description}
                            <Check
                              className={cn(
                                "ml-auto",
                                category.id === field.value
                                  ? "opacity-100"
                                  : "opacity-0",
                              )}
                            />
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger>
              {t("createMatch.advancedOptions")}
            </AccordionTrigger>
            <AccordionContent>
              <FormField
                control={form.control}
                name="platform"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("createMatch.platform.label")}</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t("createMatch.platform.placeholder")}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Platforms.map((platform) => (
                          <SelectItem value={platform.value}>
                            {platform.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="mt-4 grid grid-cols-1 gap-6 md:grid-cols-2">
                <FormField
                  control={form.control}
                  name="device"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("createMatch.device.label")}</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue
                              placeholder={t("createMatch.device.placeholder")}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="desktop">Desktop</SelectItem>
                          <SelectItem value="laptop">Laptop</SelectItem>
                          <SelectItem value="console">Console</SelectItem>
                          <SelectItem value="mobile">Mobile</SelectItem>
                          <SelectItem value="PC">PC</SelectItem>
                          <SelectItem value="PS4">PS4</SelectItem>
                          <SelectItem value="PS5">PS5</SelectItem>
                          <SelectItem value="Xbox One">Xbox One</SelectItem>
                          <SelectItem value="Xbox Series S/X">
                            Xbox Series S/X
                          </SelectItem>
                          <SelectItem value="Nintendo">Nintendo</SelectItem>
                          <SelectItem value="Android">Android</SelectItem>
                          <SelectItem value="iPhone/iPad">
                            iPhone/iPad
                          </SelectItem>
                          <SelectItem value="other">
                            {t("createMatch.otherDevice")}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="region"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("createMatch.region.label")}</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue
                              placeholder={t("createMatch.region.placeholder")}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="EU">
                            {t("Region.Europe")}
                          </SelectItem>
                          <SelectItem value="NA">
                            {t("Region.NorthAmerica")}
                          </SelectItem>
                          <SelectItem value="SU">
                            {t("Region.SouthAmerica")}
                          </SelectItem>
                          <SelectItem value="AS">{t("Region.Asia")}</SelectItem>
                          <SelectItem value="OC">
                            {t("Region.Oceania")}
                          </SelectItem>
                          <SelectItem value="AF">
                            {t("Region.Africa")}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <FormField
            control={form.control}
            name="amount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("createMatch.quantity")}</FormLabel>
                <FormControl>
                  <div className="flex items-center space-x-4">
                    <Users className="text-gray-400" />
                    <Slider
                      min={1}
                      max={20}
                      step={1}
                      value={[field.value]}
                      onValueChange={(value) => field.onChange(value[0])}
                      className="white flex-1"
                    />
                    <span className="min-w-[2ch] text-right text-lg font-semibold text-blue-400">
                      {field.value}
                    </span>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <MoneyInput
            key={form.watch("currency")}
            placeholder="0.00"
            label={t("createMatch.value")}
            name="value"
            form={form}
            before={<CurrencyInput form={form} name="currency" />}
          />
        </div>
        <Button
          variant={"pretty"}
          className="uppercase text-black"
          type="submit"
          grow
        >
          {t("createMatch.confirm")} <ChevronRight />
        </Button>
      </form>
    </Form>
  )
}
