import { queryClient } from "@/lib/reactQuery"
import { GetProductsByAccount, PutProductEnableToSell } from "@/services/request/Product"
import { TProduct } from "@/types"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Fragment, useState } from "react";
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { useTranslation } from "react-i18next";
import { useUser } from "@/react-query/query/useUser";
import { Spinner } from "@/components/Spinner";
import { Select, SelectContent, SelectItem, SelectTrigger } from "@/components/ui/select";
import { Power, PowerOff } from "lucide-react";

export function ToggleDuo({ product }: { product: TProduct }) {
  const { t } = useTranslation()

  const { data: user } = useUser()

  const { data: products } = useQuery({
    queryKey: ["getProducts"],
    enabled: user != null,
    queryFn: () => GetProductsByAccount(user!.id),
  })

  const existsSellableProduct = products?.some(
    (p) =>
      p.external_id === product.external_id &&
      p.id !== product.id &&
      p.enable_to_sell === true,
  )

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { mutateAsync } = useMutation({
    mutationFn: PutProductEnableToSell,
  })

  async function toggleEnableProduct() {
    setIsLoading(true)
    await mutateAsync({ id: product.id, enable_to_sell: !product.enable_to_sell })
    await queryClient.refetchQueries({ queryKey: ["GetProduct", product.id] })
    await queryClient.invalidateQueries({ queryKey: ["getProducts"] })
    setIsLoading(false)
  }

  function handleValueChange(value: string) {
    const shouldOpenConfirmModal = value === 'active' && !product.enable_to_sell && existsSellableProduct

    if (shouldOpenConfirmModal) {
      setOpen(true)
      return
    }

    toggleEnableProduct()
  }

  return (
    <Fragment>
      <Select value={product.enable_to_sell ? 'active' : 'inactive'} onValueChange={handleValueChange}>
        <SelectTrigger>
          {isLoading
            ? <Spinner />
            : product.enable_to_sell
              ? <Power size="14" className="text-green-400" />
              : <PowerOff size="14" className="text-destructive" />}
          {product.enable_to_sell
            ? t("toggleDuo.active")
            : t("toggleDuo.inactive")
          }
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="active">
            <div className="flex flex-col gap-1 max-w-52 text-pretty">
              <div className="font-semibold">{t("toggleDuo.select.active")}</div>
              <div className="text-xs">{t("toggleDuo.select.activeDescription")}</div>
            </div>
          </SelectItem>
          <SelectItem value="inactive">
            <div className="flex flex-col gap-1 max-w-52 text-pretty">
              <div className="font-semibold">{t("toggleDuo.select.inactive")}</div>
              <div className="text-xs">{t("toggleDuo.select.inactiveDescription")}</div>
            </div>
          </SelectItem>
        </SelectContent>
      </Select>

      <ConfirmProductEnableDialog
        isModalOpen={open}
        handleOk={toggleEnableProduct}
        handleCancel={() => setOpen(false)} />
    </Fragment >
  );
}

type ConfirmDeleteDuoDialogProps = {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
}

function ConfirmProductEnableDialog({ isModalOpen, handleOk, handleCancel }: ConfirmDeleteDuoDialogProps) {
  const { t } = useTranslation()

  return (
    <AlertDialog
      open={isModalOpen}
      onOpenChange={(newOpen) => {
        if (!newOpen) {
          handleCancel()
        }
      }}>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t("Product.Confirmation.Title")}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {t("Product.Confirmation.Description")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>
              {t("Product.Confirmation.Cancel")}
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleOk}>
              {t("Product.Confirmation.Ok")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  )
}