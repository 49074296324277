import { ArrowRight } from "lucide-react"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { useSearchParams } from "react-router-dom"
import PixPaymentForm from "@/components/PixPaymentForm"
import { env } from "@/utils/env"
import { PixICon } from "@/components/Icons/PixIcon"
import StripeIcon from "@/components/Icons/StripeIcon"

export default function PaymentMethodsPage() {
  const [searchParams, setSearchParams] = useSearchParams()

  if (searchParams.get("method") === "pix") {
    return <PixPaymentForm />
  }

  return (
    <div className="flex p-4">
      <Card className="w-full border-none shadow-none">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-white">
            Payment Methods
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <Button
            grow
            variant="outline"
            className="h-40"
            onClick={() => setSearchParams({ method: "pix" })}
          >
              <PixICon className="size-10" />
              <div className="flex items-center">Pix</div>
              <ArrowRight className="size-8" />
          </Button>

          <Button
            grow
            size={"icon"}
            variant="outline"
            className="h-40"
            onClick={() => window.open(env.STRIPE_WALLET_URL)}
          >
              <StripeIcon className="size-10 fill-white"  />
              <div className="flex items-center gap-3">Stripe</div>
              <ArrowRight className="h-6 w-6" />
          </Button>
        </CardContent>
      </Card>
    </div>
  )
}
