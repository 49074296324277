import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { useUser } from "@/react-query/query/useUser"
import { getStreamerProfilePath } from "@/lib/getRoutePath"
import { Copy, Share2 } from "lucide-react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { toast } from "sonner"

export function StreamerPublicProfile() {
  const { t } = useTranslation()
  const { data: user } = useUser()

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <div className="flex gap-2">
            <Share2 className="text-slate-600" /> {t("Profile.PublicProfile.title")}
          </div>
        </CardTitle>
        <CardDescription>{t("Profile.ShareLinkOtherPlayers")}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-wrap gap-2">
          <Input
            readOnly
            value={`${origin}${getStreamerProfilePath(user?.name)}`}
            className="max-w-[350px]"
          />
          <CopyToClipboard
            text={`${origin}${getStreamerProfilePath(user?.name)}`}
            onCopy={() => {
              toast.success(t("Profile.LinkCopied"))
            }}
          >
            <Button variant="secondary" size="icon">
              <Copy className="size-4" />
            </Button>
          </CopyToClipboard>

          <Button variant="secondary" asChild>
            <Link to={getStreamerProfilePath(user?.name)}>{t("Profile.ViewProfile")}</Link>
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}
