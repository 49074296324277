export function PixICon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={24}
      height={24}
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fill="#37c6d0"
        d="M19.262 44.037l-8.04-8.04L11 35l-1.777-1.003-5.26-5.26a6.699 6.699 0 010-9.475l5.26-5.26L11 13l.223-.997 8.04-8.04a6.699 6.699 0 019.475 0l8.04 8.04L37 13l1.777 1.003 5.26 5.26a6.699 6.699 0 010 9.475l-5.26 5.26L37 35l-.223.997-8.04 8.04a6.7 6.7 0 01-9.475 0z"
      />
      <path
        d="M35.79 11.01c-1.76.07-3.4.79-4.63 2.04l-6.81 6.77a.485.485 0 01-.7 0l-6.8-6.76a6.848 6.848 0 00-4.64-2.05L8.22 15h3.68c.8 0 1.55.31 2.12.88l6.8 6.78c.85.84 1.98 1.31 3.18 1.31s2.33-.47 3.18-1.31l6.79-6.78c.58-.57 1.33-.88 2.13-.88h3.68l-3.99-3.99zM36.1 33c-.8 0-1.55-.31-2.12-.88l-6.8-6.78c-.85-.84-1.98-1.31-3.18-1.31s-2.33.47-3.18 1.31l-6.79 6.78c-.58.57-1.33.88-2.13.88H8.22l3.99 3.99a6.84 6.84 0 004.63-2.04l6.81-6.77a.485.485 0 01.7 0l6.8 6.76a6.848 6.848 0 004.64 2.05L39.78 33H36.1z"
        opacity={0.05}
      />
      <path
        d="M36.28 11.5h-.18c-1.74 0-3.38.68-4.59 1.91l-6.8 6.77c-.19.19-.45.29-.71.29s-.52-.1-.71-.29l-6.79-6.77a6.443 6.443 0 00-4.6-1.91h-.18l-3 3h3.18c.93 0 1.81.36 2.48 1.02l6.8 6.78c.75.76 1.75 1.17 2.82 1.17s2.07-.41 2.82-1.17l6.8-6.77a3.48 3.48 0 012.48-1.03h3.18l-3-3zm-.18 22c-.93 0-1.81-.36-2.48-1.02l-6.8-6.78c-.75-.76-1.75-1.17-2.82-1.17s-2.07.41-2.82 1.17l-6.8 6.77a3.48 3.48 0 01-2.48 1.03H8.72l3 3h.18c1.74 0 3.38-.68 4.59-1.91l6.8-6.77c.19-.19.45-.29.71-.29s.52.1.71.29l6.79 6.77a6.443 6.443 0 004.6 1.91h.18l3-3H36.1z"
        opacity={0.07}
      />
      <path
        fill="#fff"
        d="M38.78 14H36.1c-1.07 0-2.07.42-2.83 1.17l-6.8 6.78c-.68.68-1.58 1.02-2.47 1.02s-1.79-.34-2.47-1.02l-6.8-6.78A4.004 4.004 0 0011.9 14H9.22l2-2h.68c1.6 0 3.11.62 4.24 1.76l6.8 6.77c.59.59 1.53.59 2.12 0l6.8-6.77A5.928 5.928 0 0136.1 12h.68l2 2zM36.1 34c-1.07 0-2.07-.42-2.83-1.17l-6.8-6.78a3.5 3.5 0 00-4.94 0l-6.8 6.78c-.76.75-1.76 1.17-2.83 1.17H9.22l2 2h.68c1.6 0 3.11-.62 4.24-1.76l6.8-6.77a1.49 1.49 0 012.12 0l6.8 6.77A5.928 5.928 0 0036.1 36h.68l2-2H36.1z"
      />
    </svg>
  )
}
