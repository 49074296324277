import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { useForm, UseFormReturn } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { cn } from "@/lib/utils"
import { TUserPixInfo, UserPixInfoSchema } from "@/types"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Card, CardContent } from "@/components/ui/card"

interface Props {
  setData: Dispatch<SetStateAction<TUserPixInfo | undefined>>
  disabled?: boolean
}
export function FirstStep({ setData, disabled }: Props) {
  const { t } = useTranslation()

  const form = useForm<TUserPixInfo>({
    resolver: zodResolver(UserPixInfoSchema(t)),
    defaultValues: {
      payment_with_pix: false,
    },
  })

  return <PaymentWithPix setData={setData} form={form!} disabled={disabled} />
}

interface PaymentWithPixProps extends Props {
  form: UseFormReturn<TUserPixInfo>
}

const PaymentWithPix = ({ setData, disabled, form }: PaymentWithPixProps) => {
  const { t } = useTranslation()

  function onSubmit(data: TUserPixInfo) {
    setData(data)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn(
          "w-full space-y-2 transition-all duration-300",
          disabled && "animate-fade-out hidden",
        )}
      >
        <FormField
          control={form.control}
          name="payment_with_pix"
          render={({ field }) => (
            <FormControl>
              <RadioGroup
                onValueChange={(value) => {
                  field.onChange(value === "pix_payment")
                }}
                defaultValue={"card_payment"}
                className="space-y-4"
              >
                <Card>
                  <CardContent className="pt-6">
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="card_payment" />
                      </FormControl>
                      <div className="space-y-1">
                        <FormLabel className="font-medium">
                          Credit Card Payment
                        </FormLabel>
                        <FormDescription>
                          Pay securely using your credit card.
                        </FormDescription>
                      </div>
                    </FormItem>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent className="pt-6">
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="pix_payment" />
                      </FormControl>
                      <div className="space-y-1">
                        <FormLabel className="font-medium">
                          PIX Payment
                        </FormLabel>
                        <FormDescription>
                          Pay instantly using PIX, Brazil's instant payment
                          system.
                        </FormDescription>
                      </div>
                    </FormItem>
                  </CardContent>
                </Card>
              </RadioGroup>
            </FormControl>
          )}
        />
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input placeholder="Enter your username" {...field} />
              </FormControl>
              <FormDescription>
                This is your public display name.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        {form.watch("payment_with_pix") && (
          <>
            <FormField
              control={form.control}
              name="client_doc"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>CPF</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter your document number"
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Enter your 11-digit document number.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="client_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nome Completo</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter your full name" {...field} />
                  </FormControl>
                  <FormDescription>
                    Enter your full name as it appears on your documents.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}
        <Button type="submit">{t("CheckOut.GameAccount.Advance")}</Button>
      </form>
    </Form>
  )
}
