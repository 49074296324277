import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { useState } from "react"
import InputMask from "react-input-mask"
import { useTranslation } from "react-i18next"

import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { PixICon } from "./Icons/PixIcon"
import { FadeWrapper } from "./FadeWrapper"
import { RadioGroup, RadioGroupItem } from "./ui/radio-group"
import { useAuthContext } from "@/context/auth"
import { useSetPixMethod } from "@/react-query/mutation/use-set-pix-method"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { validateCPF, validateCNPJ } from "@/utils/validator"



// Helper function to remove mask before validation and submission
const removeMask = (value: string): string => {
  return value.replace(/[^\d]/g, "");
};

const formSchema = z
  .object({
    document_type: z.enum(["cpf", "cnpj"]),
    document: z.string().refine(
      (val) => {
        if (removeMask(val).length === 0) return false;
        return true;
      }, 
      { message: "Documento é obrigatório" }
    ),
    pix_key: z.string().email({ message: "Chave PIX deve ser um email válido" }),
  })
  .superRefine((data, ctx) => {
    const unmaskedDocument = removeMask(data.document);
    
    if (data.document_type === "cpf") {
      if (unmaskedDocument.length !== 11) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "CPF deve ter 11 dígitos",
          path: ["document"],
        });
        return;
      }
      
      if (!validateCPF(data.document)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "CPF inválido",
          path: ["document"],
        });
      }
    }
    
    if (data.document_type === "cnpj") {
      if (unmaskedDocument.length !== 14) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "CNPJ deve ter 14 dígitos",
          path: ["document"],
        });
        return;
      }
      
      if (!validateCNPJ(data.document)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "CNPJ inválido",
          path: ["document"],
        });
      }
    }
  });

export default function PixPaymentForm() {
  const { t } = useTranslation();
  const { user } = useAuthContext()
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [formValues, setFormValues] = useState<z.infer<typeof formSchema> | null>(null);
  
  const { mutate: setPixMethod } = useSetPixMethod()
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      document: "",
      pix_key: "",
      document_type: "cpf",
    },
  });

  // Define mask based on document type
  const getDocumentMask = () => {
    return form.watch("document_type") === "cpf" 
      ? "999.999.999-99" 
      : "99.999.999/9999-99";
  };

  function onSubmit(values: z.infer<typeof formSchema>) {
    // Unmask document before saving
    const unmaskedValues = {
      ...values,
      document: removeMask(values.document)
    };
    setFormValues(unmaskedValues);
    setShowConfirmDialog(true);
  }

  function handleConfirm() {
    if (formValues) {
      setPixMethod({
        account_id: user.userId,
        content: formValues,
      });
    }
    setShowConfirmDialog(false);
  }

  return (
    <FadeWrapper>
      <Card className="mx-auto w-full">
        <CardHeader>
          <CardTitle className="flex items-center gap-x-2">
            <PixICon className="size-10" />
            {t("PixPaymentForm.title")}
          </CardTitle>
          <CardDescription>
            {t("PixPaymentForm.description")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="document_type"
                render={({ field }) => (
                  <FormItem>
                    <Card>
                      <CardHeader>
                        <CardTitle>{t("PixPaymentForm.personType.title")}</CardTitle>
                        <CardDescription>
                          {t("PixPaymentForm.personType.description")}
                        </CardDescription>
                      </CardHeader>
                      <FormControl>
                        <RadioGroup
                          className="flex"
                          onValueChange={field.onChange}
                          defaultValue="cpf"
                        >
                          <CardContent className="flex items-center space-x-3">
                            <FormControl>
                              <RadioGroupItem
                                value="cpf"
                              />
                            </FormControl>
                            <FormLabel>CPF</FormLabel>
                          </CardContent>
                          <CardContent className="flex items-center space-x-3">
                            <FormControl>
                              <RadioGroupItem value="cnpj" />
                            </FormControl>
                            <FormLabel>CNPJ</FormLabel>
                          </CardContent>
                        </RadioGroup>
                      </FormControl>
                    </Card>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="document"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("PixPaymentForm.document.label")}
                    </FormLabel>
                    <FormControl>
                      <InputMask
                        mask={getDocumentMask()}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        maskPlaceholder={null}
                      >
                        {(inputProps: any) => (
                          <Input
                            className="ring-cyan-500 ring-offset-cyan-500 focus-visible:ring-cyan-500"
                            placeholder={form.watch("document_type") === "cpf" ? "000.000.000-00" : "00.000.000/0000-00"}
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    </FormControl>
                    <FormDescription>
                      {form.watch("document_type") === "cpf" 
                        ? t("PixPaymentForm.document.cpfDescription")
                        : t("PixPaymentForm.document.cnpjDescription")}
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="pix_key"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("PixPaymentForm.pixKey.label")}</FormLabel>
                    <FormControl>
                      <Input
                        className="ring-cyan-500 ring-offset-cyan-500 focus-visible:ring-cyan-500"
                        placeholder={t("PixPaymentForm.pixKey.placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      {t("PixPaymentForm.pixKey.description")}
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                type="submit"
                className="w-full bg-cyan-600 hover:bg-cyan-700"
              >
                {t("PixPaymentForm.submit")}
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>

      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t("PixPaymentForm.confirmDialog.title")}</AlertDialogTitle>
            <AlertDialogDescription>
              {t("PixPaymentForm.confirmDialog.description")}
              <div className="mt-4 space-y-2">
                <div>
                  <span className="font-semibold">{t("PixPaymentForm.confirmDialog.documentType")}:</span> {formValues?.document_type === "cpf" ? "CPF" : "CNPJ"}
                </div>
                <div>
                  <span className="font-semibold">{t("PixPaymentForm.confirmDialog.document")}:</span> {
                    formValues?.document_type === "cpf" 
                      ? formValues?.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
                      : formValues?.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                  }
                </div>
                <div>
                  <span className="font-semibold">{t("PixPaymentForm.confirmDialog.pixKey")}:</span> {formValues?.pix_key}
                </div>
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t("PixPaymentForm.confirmDialog.cancel")}</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirm} className="bg-cyan-600 hover:bg-cyan-700">
              {t("PixPaymentForm.confirmDialog.confirm")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      
    </FadeWrapper>
  )
}
