import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Spinner } from "@/components/Spinner";
import { useSetPixMethod } from "@/react-query/mutation/use-set-pix-method";
import { useAuthContext } from "@/context/auth";
import { useNavigate } from "react-router-dom";
import { validateCPF, validateCNPJ } from "@/utils/validator";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";

// Helper function to remove mask before validation and submission
const removeMask = (value: string): string => {
  return value.replace(/[^\d]/g, "");
};

// Document types
const documentTypes = ["cpf", "cnpj"] as const;

// Create a schema with basic validation rules
const formSchema = z
  .object({
    pix_key: z.string().email({ message: "PixSetup.pixKey.error" }),
    document_type: z.enum(documentTypes),
    document: z.string().min(1, { message: "PixSetup.document.errors.required" }),
  }).superRefine((data) => {
    const unmaskedDocument = removeMask(data.document);
    
    if (data.document_type === "cpf") {
      if (unmaskedDocument.length !== 11) {
        return { document: "PixSetup.document.errors.cpfLength" }
      }
      if (!validateCPF(data.document)) {
        return { document: "PixSetup.document.errors.cpfInvalid" }
      }
    }
    if (data.document_type === "cnpj") {
      if (unmaskedDocument.length !== 14) {
        return { document: "PixSetup.document.errors.cnpjLength" }
      }
      if (!validateCNPJ(data.document)) {
        return { document: "PixSetup.document.errors.cnpjInvalid" }
      }
    }
  })

type FormValues = z.infer<typeof formSchema>;

export function PixSetup() {
  const { t } = useTranslation();
  const { mutate: setPixMethod, isPending } = useSetPixMethod()
  const { user} = useAuthContext()
  const navigate = useNavigate()
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      pix_key: "",
      document: "",
    },
  });

  const onSubmit = async (values: FormValues) => {
    // Unmask document before saving
    const unmaskedValues = {
      ...values,
      document: removeMask(values.document)
    };
    
    setPixMethod({
      account_id: user.userId,
      content: {
        ...unmaskedValues,
        isTalent: true,
      },
    })
    navigate("/portal")
  };

  // Define mask based on document type
  const getDocumentMask = () => {
    return form.watch("document_type") === "cpf" 
      ? "999.999.999-99" 
      : "99.999.999/9999-99";
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="document_type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("PixSetup.documentType.label")}</FormLabel>
              <Select
                onValueChange={(value) => {
                  field.onChange(value);
                  // Clear the document field when changing types
                  form.setValue("document", "");
                }}
                defaultValue={field.value}
                disabled={isPending}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("PixSetup.documentType.placeholder")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem className="uppercase" value="cpf">{t("PixSetup.documentType.cpf")}</SelectItem>
                  <SelectItem className="uppercase" value="cnpj">{t("PixSetup.documentType.cnpj")}</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="document"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("PixSetup.document.label")}</FormLabel>
              <FormControl>
                <InputMask
                  mask={getDocumentMask()}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  maskPlaceholder={null}
                >
                  {(inputProps: any) => (
                    <Input
                      placeholder={form.watch("document_type") === "cpf" 
                        ? t("PixSetup.document.cpfPlaceholder") 
                        : t("PixSetup.document.cnpjPlaceholder")}
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="pix_key"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("PixSetup.pixKey.label")}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t("PixSetup.pixKey.placeholder")}
                  {...field}
                  disabled={isPending}
                  type="email"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" disabled={isPending} className="w-full">
          {isPending && <Spinner className="mr-2" />}
          {t("PixSetup.submit")}
        </Button>
      </form>
    </Form>
  );
} 