import { db } from "@/services/firebase/db"
import { TOrder } from "@/types"
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

interface OrderProps {
  client_secret: string
  order_id: string
}

export default function QRCode({ order }: { order: OrderProps }) {
  const navigate = useNavigate()
  useEffect(() => {
    const q = query(
      collection(db, "orders"),
      where("order_id", "==", order.order_id),
      orderBy("createdAt", "desc"),
      limit(1),
    )
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {

        const data = doc.data() as TOrder
        if (data.status === "PAID") {
          navigate(`/completion?order=${order.order_id}`)
        }
      })
    })
    return () => unsubscribe()
  }, [navigate, order])

  return (
    <div className="flex items-center justify-center p-6">
      <div className="rounded-xl h-80 w-80 bg-gradient-to-b from-white/60 to-white/20 p-6 shadow-lg">
        <img
          src={`data:image/png;base64,${order.client_secret}`}
          alt="QR Code"
          className="size-full rounded-lg"
        />
      </div>
    </div>
  )
}
