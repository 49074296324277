import { useEffect, useState } from "react"
import { Input } from "@/components/ui/input"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { GetAllAccounts } from "../../services/request/Account"
import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom"
import { UserPicture } from "@/components/UserPicture"
import { Spinner } from "@/components/Spinner"
import {
  getOverviewPath,
  getStreamerProfilePath,
} from "@/lib/getRoutePath"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"

export type TTalent = {
  id: string
  name: string
  avatar: string
  isTalent: boolean
  profile_description?: string | undefined
}

export function Talents() {
  const [talents, setTalents] = useState<TTalent[]>([])
  const [searchTalent, setSearchTalent] = useState<string>("")
  const [filteredTalents, setFilteredTalents] = useState<TTalent[]>([])
  const { t } = useTranslation()

  const handleSearchTalent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTalent(e.target.value)
    if (e.target.value === "") {
      setFilteredTalents(talents)
    } else {
      setFilteredTalents(
        talents.filter((talent) =>
          talent.name.toLowerCase().includes(e.target.value.toLowerCase()),
        ),
      )
    }
  }

  const { data, isLoading } = useQuery({
    queryKey: ["talents"],
    queryFn: () => GetAllAccounts(),
    staleTime: 1000 * 60 * 5,
    refetchInterval: 1000 * 60 * 5,
  })

  useEffect(() => {
    if (data) {
      setTalents(data)
    }
  }, [data])

  useEffect(() => {
    setFilteredTalents(talents)
  }, [talents])

  return (
    <div className="w-full space-y-4 md:container">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to={getOverviewPath()}>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to={getOverviewPath()}>
              Home
              {t("TalentsOnline.title")}
            </Link>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <h1 className="text-2xl font-bold">{t("TalentsOnline.title")}</h1>
      <Input
        placeholder={t("TalentsOnline.search")}
        value={searchTalent}
        onChange={handleSearchTalent}
      />
      {isLoading ? (
        <div className="flex h-[17rem] items-center justify-center">
          <Spinner className="size-10 text-secondary" />
        </div>
      ) : (
        <div className="flex w-full flex-wrap gap-x-2 gap-y-4 md:gap-x-4">
          {filteredTalents.map((talent) => (
            <Streamer key={talent.id} talent={talent} />
          ))}
        </div>
      )}
    </div>
  )
}

function Streamer({ talent }: { talent: TTalent }) {
  const { t } = useTranslation()

  return (
    <div className="group relative space-y-4 rounded-lg border border-transparent bg-card p-2 transition-colors hover:border-slate-700 md:p-4">
      <Link to={getStreamerProfilePath(talent.name)}>
        <UserPicture
          src={talent.avatar}
          alt={talent.name}
          size="medium"
          className="transition-duration-500 transition-all group-hover:scale-105"
        />
      </Link>

      <div className="space-y-1 text-sm">
        <h2 className="font-medium leading-none">@{talent.name}</h2>
        <p className="text-ellipsis text-xs text-muted-foreground">
          {talent.profile_description}
        </p>
      </div>
      <Button size="sm" className="w-full" asChild>
        <Link
          to={getStreamerProfilePath(talent.name)}
          className="hover:text-white"
        >
          {t("TalentsOnline.viewProfile")}
        </Link>
      </Button>
    </div>
  )
}
