import { HTMLMotionProps, motion } from 'motion/react'

export function FadeWrapper({
  children,
  duration = 3,
  ...props
}: {
  children: React.ReactNode
  duration?: number
} & HTMLMotionProps<'div'>) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration }}
      {...props}
    >
      {children}
    </motion.div>
  )
}
