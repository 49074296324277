import { GetLinkToConnectStripe } from "../../../services/request/Account"
import { useUser } from "../../../react-query/query/useUser"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { SidebarMenuButton, SidebarMenuItem } from "@/components/ui/sidebar"
import { Button } from "@/components/ui/button"
import { Spinner } from "@/components/Spinner"

export function BecomeATalent() {
  const { data: user } = useUser()
  const { t } = useTranslation()

  const { refetch, isLoading } = useQuery({
    queryKey: ["StripeConnect"],
    queryFn: async () => {
      const { link_to_connect } = await GetLinkToConnectStripe()
      window.location.href = link_to_connect
    },
    enabled: false,
  })

  const stripeCheckingAccount = () => {
    return user?.stripe_pending_requirements?.includes(
      "individual.political_exposure",
    )
  }

  if (user?.isTalent) return null

  return (
    <SidebarMenuItem>
      <SidebarMenuButton asChild>
        <Button
          variant="secondary"
          onClick={() => refetch()}
          disabled={isLoading}
        >
          {isLoading && <Spinner />}

          {!user?.isTalent && !user?.stripe_pending_requirements
            ? t("streamerSignupDuoToPlay.signup")
            : stripeCheckingAccount()
              ? t("streamerSignupDuoToPlay.weAreChecking")
              : t("streamerSignupDuoToPlay.weNeedMoreInfo")}
        </Button>
      </SidebarMenuButton>
    </SidebarMenuItem>
  )
}
