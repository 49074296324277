import { SetTalentPix, TTalentPixConfig } from "@/services/request/Account"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"
import { USER_QUERY_KEY } from "../query/useUser"
import { queryClient } from "@/lib/reactQuery"

type PixMethod = {
  account_id: string
  content: TTalentPixConfig
}

export function useSetPixMethod() {
  return useMutation({
    mutationFn: async (data: PixMethod) => {
      await SetTalentPix(data)
    },
    onSuccess: () => {
      toast.success("Pix configurado com sucesso")
      queryClient.invalidateQueries({ queryKey: [USER_QUERY_KEY] })
    },
    onError: () => {
      toast.error("Erro ao configurar Pix")
    },
  })
}