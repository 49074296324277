import { StepProgress } from "@/components/StepProgress";
import { useTranslation } from "react-i18next";

export const steps = [
  {
    step: 1,
    title: 'streamerOnboarding.steps.duo.title',
  },
  {
    step: 2,
    title: 'streamerOnboarding.steps.payment_methods.title',
  }
] as const

export function StreamerOnboardingSteps({ currentStep }: { currentStep: number }) {
  const { t } = useTranslation()

  const translatedSteps = steps.map((step) => ({ title: t(step.title) }))

  return (
    <div className="m-auto max-w-[400px]">
      <StepProgress
        currentStep={currentStep}
        steps={translatedSteps}
      />
    </div>
  )
}