import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown"
import { SidebarMenuButton, SidebarMenuItem } from "@/components/ui/sidebar"
import { ChevronUp } from "lucide-react"
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from "react-i18next"

const availableLanguages = [
  {
    value: "pt",
    label: "Brasil",
    flag: "BR",
  },
  {
    value: "en",
    label: "United States",
    flag: "US",
  },
  {
    value: "es",
    label: "España",
    flag: "ES",
  },
  {
    value: "fr",
    label: "France",
    flag: "FR",
  },
]

export function LanguageChange() {
  const { i18n } = useTranslation()

  const currentLanguage = availableLanguages.find(
    (lang) => lang.value === i18n.language,
  )

  if (!currentLanguage) return null

  return (
    <SidebarMenuItem>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <SidebarMenuButton>
            <ReactCountryFlag
              svg
              countryCode={currentLanguage.flag}
              alt={`${currentLanguage.label} flag`}
              width={16}
              height={16}
            />
            <span>{currentLanguage.label}</span>

            <ChevronUp className="ml-auto" />
          </SidebarMenuButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          side="top"
          className="w-[--radix-popper-anchor-width]"
        >
          {availableLanguages.map((lang) => (
            <DropdownMenuItem
              key={lang.value}
              onClick={() => {
                i18n.changeLanguage(lang.value)
                localStorage.setItem("@DuoToPlay:lang", lang.value)
              }}
            >
              <ReactCountryFlag
                svg
                countryCode={lang.flag}
                alt={`${lang.label} flag`}
                width={16}
                height={16}
              />

              <span className="font-sans">{lang.label}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </SidebarMenuItem>
  )
}
