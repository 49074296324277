import { SVGProps } from "react"

function StripeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={100}
      height={100}
      viewBox="0 0 48 48"
      {...props}
    >
      <path d="M11.5 6C8.48 6 6 8.48 6 11.5v25c0 3.02 2.48 5.5 5.5 5.5h25c3.02 0 5.5-2.48 5.5-5.5v-25C42 8.48 39.52 6 36.5 6h-25zm0 3h25c1.398 0 2.5 1.102 2.5 2.5v25c0 1.398-1.102 2.5-2.5 2.5h-25A2.478 2.478 0 019 36.5v-25C9 10.102 10.102 9 11.5 9zm13 4c-5.905 0-8 3.502-8 6.5 0 4.522 4.265 5.943 6.467 6.559 2.447.72 2.533 1.765 2.533 2.212 0 .583-.24.97-.424 1.159-.524.542-1.451.646-2.398.547-2.065-.217-4.167-1.011-5.334-1.512a.247.247 0 00-.344.23v4.662c0 .213.13.403.33.473 1.735.6 3.961 1.17 6.17 1.17 5.905 0 8-3.636 8-6.75 0-3.265-2.19-5.557-6.512-6.818-2.477-.753-2.488-1.61-2.488-1.993 0-.466.228-.785.389-.943.496-.489 1.511-.578 2.445-.459 2.064.183 4.157.98 5.32 1.488a.248.248 0 00.346-.23v-4.652a.495.495 0 00-.33-.473c-1.735-.6-3.961-1.17-6.17-1.17z" />
    </svg>
  )
}

export default StripeIcon
