import { useTranslation } from "react-i18next"
import { useUser } from "../../react-query/query/useUser"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"

import { StreamerOverview } from "@/views/Overview/StreamerOverview"
import { FanOverview } from "@/views/Overview/FanOverview"

export default function Overview() {
  const location = useLocation()
  const { t } = useTranslation()
  const { data: user } = useUser()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.resume.title")}</title>
        <meta name="description" content={t("Helmet.resume.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>

      {!user ? null : user.isTalent ? <StreamerOverview /> : <FanOverview />}
    </>
  )
}
